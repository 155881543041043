import {Routes,Route,BrowserRouter } from 'react-router-dom';
import Header from "./Components/Header"
import LoginContextProvider, { LoginContext } from './Context/LoginContext';
import ResetPassword from "./Components/ResetPassword"
import "./App.css"
import PageNotFound from './Components/PageNotFound';
import Articles from "./Components/Shop/Articles"
import Cours from "./Components/Shop/Cours"
import Livers from "./Components/Shop/Livers"
import Cart from "./Components/Shop/Cart"
import ProdcutDetails from './Components/Shop/ProdcutDetails';
import Checkout from './Components/Shop/Checkout';
import Payment from './Payment/Payment';
import Dashboard from './dashboard/Home/Dashboard';
import DashbordContext from './Context/DashbordContext';
 
 
 
 
 
function App() {
 
 
  
  return (
    <>
    <LoginContextProvider>
      <DashbordContext>
        <BrowserRouter>
        <Routes>
          <Route path='/' element={<Header/>}/>
          <Route path='/product_details/:id_product/:type' element={<ProdcutDetails/>}/>
          <Route path='*' element={<PageNotFound/>}/>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/rest_password/:token/:email' element={<ResetPassword/>}/>
          <Route path='/articles' element={<Articles/>}/>
          <Route path='/cours' element={<Cours/>}/>
          <Route path='/livers' element={<Livers/>}/>
          <Route path='/checkout' element={<Checkout/>}/>
          <Route path='/checkout/:info' element={<Payment/>}/>
          <Route path='/dashboard' element={<Dashboard/>}/>
        </Routes>
         
      </BrowserRouter>
      </DashbordContext>

     
    </LoginContextProvider>
 
 
    </>
  );
}

export default App;
