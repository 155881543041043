 const INTIINITILAL_STATE={
    user: {
        id:0,
        name:"",
        email:"",
        role:"",
        connected:false
    }
}

export default function UserReducer(state=INTIINITILAL_STATE,action){
 
    if (action.type==="connected_user") {
        let new_user={
            id:action.payload.id,
            name:action.payload.name,
            email:action.payload.email,
            role:action.payload.role,
            connected:true
        }
        return {user:new_user}
    }
    if (action.type==="logout") {
        let logUser={
            id:0,
            name:"",
            email:"",
            role:"",
            connected:false
        }
        return {user:logUser};
    }
    return state;
}