 

 

 const INTIINITILAL_STATE={
    panier:[]
}
export default function CartReducer(state=INTIINITILAL_STATE,action){
    
    
   
    if (action.type==="addToPanie") 
    {      
        let product={}
        let newProduct=[];
        const item =state.panier.find((e) =>(e.type_product===action.payload.type_product && e.id_product === action.payload.id_product));
        if (item) 
        {
            newProduct=[...state.panier];
            newProduct[item]=item.quantity++;
            return {panier:newProduct};
        }
        else
        {
            product.id_product=action.payload.id_product;
            product.quantity=1;
            product.type_product=action.payload.type_product
            product.title=action.payload.title;   
            product.price=action.payload.price;   
            product.image=action.payload.image;
            newProduct=[...state.panier];
            newProduct.push(product);
           
            return{panier:newProduct};
        }
    }
    else if (action.type==="remove") {
        let products=[]
        for (let i = 0; i < state.panier.length; i++) {
             if (!(state.panier[i].type_product===action.payload.type_product&&state.panier[i].id_product===action.payload.id_product)) {
                products.push(state.panier[i])
             }
            
        }
        return {panier:products}
    }

    else if (action.type==="incraseQuantity") {
        const item =state.panier.find((e) =>(e.type_product===action.payload.type_product && e.id_product === action.payload.id_product));
        let newProduct=[];
        if (item) {
            newProduct=[...state.panier];
            newProduct[item]=item.quantity++;
            return {panier:newProduct};
        }
    }
    else if (action.type==="decraseQuantity") {
        const item =state.panier.find((e) =>(e.type_product===action.payload.type_product && e.id_product === action.payload.id_product));
        let newProduct=[];
        if (item) {
            newProduct=[...state.panier];
            newProduct[item]=item.quantity--;
            return {panier:newProduct};
        }
    }
 

    return state;
}