import React, {  useContext, useState } from 'react'
import { LoginContext } from '../Context/LoginContext';
import { toast ,Bounce} from 'react-toastify';
import axios from "axios"
import { useDispatch } from 'react-redux';

export default function Login() {
    const {setLogin,setisRegister,setIsReset}=useContext(LoginContext);
    const [email,setemail]=useState('');
    const [password,setpassword]=useState('');
    const [emailIsvalid,setEmailIsValid]=useState('');
    const Dispatch=useDispatch();
    //check foramt eamil is valid
    const checkFormatEmail=()=>{
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
        {
          document.getElementById("emailLogin").style.border="2px solid red"
          setEmailIsValid(false);
        }
        else{
          setEmailIsValid(true);
          document.getElementById("emailLogin").style.border="2px solid green"
        }
    }

    const Login =async()=>{
      if (!emailIsvalid) {
        document.getElementById("loginEmailError").innerHTML="format email incorect";
        document.getElementById("loginEmailError").style.color="red";
        document.getElementById("loginEmailError").style.marginLeft="15px";
        document.getElementById("loginEmailError").style.fontWeight="bold";
        return 0;
      }
      document.getElementById("loginEmailError").innerHTML="";
      document.getElementById("loginEmailError").style.marginTop="0px";
      document.getElementById("loginEmailError").style.marginLeft="opx";
      if (password.length<8) {
 
        document.getElementById("loginPasswordError").innerHTML="Passord must be more than 8 whegiths";
        document.getElementById("loginPasswordError").style.color="red";
        document.getElementById("loginPasswordError").style.marginTop="10px";
        document.querySelector(".remember-forgot").style.marginTop="20px"
        document.getElementById("loginPasswordError").style.marginLeft="10px";
        document.getElementById("loginPasswordError").style.fontWeight="bold";

        return 0;
      }
      document.getElementById("loginPasswordError").innerHTML="";
      document.getElementById("loginPasswordError").style.marginTop="0px";
      document.getElementById("loginPasswordError").style.marginLeft="0px";
    
      await axios.post("https://edu-hasnaoui.com/api/login",{email:email,password:password})
      .then(res=>{
        
          document.getElementById("emailLogin").style.border="2px solid rgba(255, 255, 255, .2)"
          window.location="/";
         
          Dispatch(
            {
              type:"connected_user",
               payload:{
                id:res.data.user.id,
                name:res.data.user.name,
                email:res.data.user.email,
                role:res.data.user.Role,
              }
            }
          )
     
      })
      .catch(err=>{
 
        toast.error(''+err.response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
          setpassword('')
      })


    }
  return (
<div className="wrapper">
    <h2>SE CONNECTER</h2>
    <div className="input-box">
      <input id='emailLogin'  value={email} onChange={(e)=>setemail(e.target.value)} type="email" onKeyUp={checkFormatEmail} placeholder="Email" required/>
      <i className='bx bx-envelope'></i>
      <p id='loginEmailError'></p>
    </div>
    <div className="input-box">
      <input type="password" value={password} onChange={(e)=>setpassword(e.target.value)} placeholder="Mot de passe" required/>
      <i className='bx bxs-lock-alt' ></i>
      <p  id='loginPasswordError'></p>
    </div>
    <div className="remember-forgot">
      <label><input id='loginPasswordError' type="checkbox"/></label>
      <a type='button' onClick={()=>{setIsReset(true);setLogin(false);setisRegister(false)}}>Mot de passe oublié</a>
    </div>
    <button type="submit" onClick={Login} className="btn">Se connecter</button>
    <div className="register-link">
      <p style={{ color:"white" }}>Vous n'avez pas de compte ?<a onClick={()=>{setisRegister(true) ;setLogin(false)}} type='button'>S'inscrire</a></p>
    </div>
</div>  
  )
}
