import React, { useEffect, useState } from 'react'
import NavbarShop from './NavbarShop'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Bounce, ToastContainer, toast } from 'react-toastify'
import Footer from '../Footer'

export default function ProdcutDetails() {

    const [product,setProduct]=useState([])
    const {id_product,type}=useParams();
    const id_user=useSelector(state=>state.UserReducer).user.id;
    const Dispatch=useDispatch();
    //get prodcut from data base
    const getProdcut=()=>
      {
        axios.get(`https://edu-hasnaoui.com/api/${type}/${id_product}`)
        .then(res=>setProduct(res.data))
        .catch(err=>err);
      }
    useEffect(getProdcut,[]);
    const addSuccefully=()=>{
      toast.success('adding to cart successfully', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
        return 0
    }

  
    //ADD product to panie
    const addProductToPanie=()=>{
         Dispatch({
          type:"addToPanie",
          payload:{
            id_user:id_user,
            id_product:product[type].id,
            type_product:type,
            price:product[type].price,
            image:product[type].image,
            title:product[type].title,
          }
         })
         Dispatch({
          type:"countProdcuts"
        })
       addSuccefully();
    }
    let image=product[type]?product[type].image:""
    console.log(image)
  return (
 <>
  <NavbarShop />
  <ToastContainer
      position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
    <ToastContainer />
  

        
<div className="card-wrapper">
      <div className="carddetailproduct">
        {/* card left */}
        <div className="product-imgs">
          <img src={"https://edu-hasnaoui.com/image/"+image}alt="" />
        </div>
        {/* card right */}
        <div className="product-content">
          <h2 className="product-title">{product[type]&&product[type].title}</h2>
          <div className="product-rating">
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star"></i>
            <i className="fas fa-star-half-alt"></i>
            <span >4.7 </span>
          </div>

          <div className="product-price">
        
            <p className="new-price">Prix : <span>{product[type]&&product[type].price}  DH</span></p>
            <p className="author">Auteur : <span>{product[type]&&product[type].author}</span></p>
          </div>

          <div className="product-decription">
            <h2>Description:</h2>
            <p> 
            {product[type]&&product[type].description}  
  
          
            </p>
            
 
          </div>

          <div className="purchase-info">
            <button onClick={addProductToPanie} type="button" className="btn">
              Add to Cart <i className="fas fa-shopping-cart"></i>
            </button>
        
          </div>
        </div>
      </div>
    </div>

 
 <Footer/>
 
 </>
  )
}
