import React, { useContext } from 'react'
import { LoginContext } from '../Context/LoginContext'
import Login from './Login';
import Register from './Register';
import ForgetPassord from './ForgetPassord';
 

export default function Accuiel() {
  const {isLogin,isRegister,isReset}=useContext(LoginContext);

  return (  
    
  <> 
    <section id="accuiel">
      <div className="hero container">
        <div>
          <h1 className='h1hero'>Découvrez notre sélection de<span></span></h1>
          <h1 className='h1hero'> <span></span></h1>
          <h1 className='h1hero'>Livres et Articles<span ></span></h1>
          <a href="/livers" type="button" className="cta">Cliquer ici</a>
        </div>
        {isLogin&& <Login/>}
        {isRegister&&<Register/>}
        {isReset&&<ForgetPassord/>}
      </div>
    </section>   
  </> 
  )
}
