import React, { useState } from 'react'
import { createContext } from "react";

export const LoginContext=createContext();

export default function LoginContextProvider({children}) {
  const [isLogin,setLogin]=useState(false)
  const [isRegister,setisRegister]=useState(false)
  const [isReset,setIsReset]=useState(false);
  const [activeFooter,setActiveFooter]=useState(true);
  
  return (
  <LoginContext.Provider value={{isLogin,setLogin,isRegister ,setisRegister,isReset,setIsReset,activeFooter,setActiveFooter}}>
    {children}
  </LoginContext.Provider>
  )
}
