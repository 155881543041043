import React, { useContext, useEffect, useState } from 'react'
import "./products.css"
import axios from 'axios';
import { pageDashbord } from '../../Context/DashbordContext';
export default function Products({element,productType,ajouterProduct}) {
   
    const [product,setProduct]=useState([]);
    const [viewAll,setviewAll]=useState(4)
    const[load,setLoad]=useState(0);
    const {setProducts,setEdit,setId_Product,setType_Product,setAjouterProduct,nameSearch}=useContext(pageDashbord);
   //get all articles from data base by api
    const getAllArticles=()=>{
     axios.get(`https://edu-hasnaoui.com/api/${element}`)
     .then(res=>setProduct((element==="pdf"?res.data["Pfd"]:res.data[element+"s"])))
     .catch(err=>err);
    
 
    }
    const remove=(id)=>{
      let ansuer= window.confirm('Are you sure you want to delete this item?')
        if (ansuer) {
         axios.delete(`https://edu-hasnaoui.com/api/${element}/${id}`)
         .then(res=>alert(res.data.delete))
         .catch(err=>err);
         getAllArticles();
         setLoad(1)
         }
         return;
           
     }

    useEffect(getAllArticles,[element,load])

 

  function EditProdcut(id,type_product){
    setEdit(true);
    setProducts(false);
    setId_Product(id);
    setType_Product(type_product);
    setAjouterProduct(false);
  }
 
  const AjouterProduct=(element)=>{
    setType_Product(element);
    setAjouterProduct(true);
    setProducts(false);
    setEdit(false)
  }
  const search=()=>{
      axios.get(`https://edu-hasnaoui.com/api/${element}s/search?wordseach=${nameSearch}`)
      .then(res=>setProduct(res.data["products"]))
      .catch(err=>err)
      if (product.length==0) {
        getAllArticles();
      }
  }
  
   
 
  useEffect(search,[nameSearch]);
  return (
    <div className='detailsProducts'>
     

<div className="productsDashboard"  >
        <div className="cardHeader">
            <h2 style={{ fontSize:"15px",fontWeight:"bold" }}>{productType}</h2>
            <button onClick={()=>AjouterProduct(element)}>Ajouter {ajouterProduct}</button>
            <a style={{ color:"white" }} onClick={()=>setviewAll(product.length)} className="btn">View All</a>
        </div>

        <table>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Author</th>
                    <th>Published Date</th>
                    <th>Edit</th>
                    <th>Remove</th>
                </tr>
            </thead>

            <tbody>
              {
                product.map((item,i)=>{
                    if (i<viewAll) {
                        return<tr key={i}>
                        <td  ><img width={70} src={"https://edu-hasnaoui.com/image/"+item.image} /></td>
                        <td style={{ fontWeight:"bold",fontSize:"11px" ,fontFamily:""}}>{item.title.slice(0,20)}...</td>
                        <td   >{item.price}DH</td>
                        <td>{item.author}</td>
                        <td>{item.published_date}</td>
                        <td onClick={()=>EditProdcut(item.id,element)}><a type='button'style={{ color:"white" }}  className="status delivered"  >Edit</a></td>
                        <td><a onClick={()=>remove(item.id)} type='button'style={{ color:"white" }}  className="status return">X</a></td>
                    </tr>
                    }
                })
              }

                
            </tbody>
        </table>
    </div>
    </div>
  )
}
