import React, { useState } from 'react'
import axios from "axios"
import { toast ,Bounce} from 'react-toastify';
export default function ForgetPassord() {
    const [emailisvalid,setEmailIsValid]=useState(false)
    const [email,setemail]=useState("");
    const checkFormatEmail=()=>{
    if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
      {
        document.getElementById("emailLogin").style.border="2px solid red"
        setEmailIsValid(false);
      }
      else
      {
        setEmailIsValid(true);
        document.getElementById("emailLogin").style.border="2px solid green"
      }
    }
    const restPassword=async()=>{
    if (!emailisvalid) 
    {
      document.getElementById("loginEmailError").innerHTML="format email incorect";
      document.getElementById("loginEmailError").style.color="red";
      document.getElementById("loginEmailError").style.marginLeft="15px";
      document.getElementById("loginEmailError").style.fontWeight="bold";
      return 0;
    }
    document.getElementById("loginEmailError").innerHTML="";
    document.getElementById("loginEmailError").style.color="";
    document.getElementById("loginEmailError").style.marginLeft="";
    document.getElementById("loginEmailError").style.fontWeight="";
    await axios.post("https://edu-hasnaoui.com/api/forgetPassword",{email:email})
    .then(res=>
    {
      toast.success(''+res.data.message,{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setemail('');
      document.getElementById("emailLogin").style.border="2px solid rgba(255, 255, 255, .2)"
    })
    .catch(err=> 
    {
          
      toast.error(''+err.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
              
      
    }      
  )}
  return (
    <div className="wrapper forgetPassword">
      <h2>Reset Password</h2>
      <div className="input-box">
        <input id='emailLogin'  value={email} onChange={(e)=>setemail(e.target.value)} type="email" onKeyUp={checkFormatEmail} placeholder="Email" required/>
        <i className='bx bx-envelope'></i>
        <p id='loginEmailError'></p>
      </div>
      <button type="submit" onClick={restPassword} className="btn">Sent Password Reset Link</button>
   </div>  
)}
