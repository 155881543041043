import React, {useContext, useEffect, useState } from 'react'
import { pageDashbord } from '../../../Context/DashbordContext'
import axios from 'axios'
import { Bounce, ToastContainer, toast } from 'react-toastify'

export default function EditProduct() {
    const {id_product,type_product}=useContext(pageDashbord)
    const[product,setProduct]=useState([])
    const [title,setTitle]=useState('');  
    const [author,setAoutor]=useState('');  
    const [description,setDescription]=useState('');  
    const [image,setImage]=useState({pictureAsFile:null,prictureView:null});  
    const [date,setDate]=useState('');  
    const [price,setPrice]=useState(0);  
 
   
    function getProduct(){
       axios.get("https://edu-hasnaoui.com/api/"+type_product+"/"+id_product)
       .then(res=>{
        setProduct(res.data[type_product])
    }
    )
    } 
    useEffect(getProduct,[type_product])
   

    const uploadPicture=(e)=>{
        setImage({
            prictureView:URL.createObjectURL(e.target.files[0]),
            pictureAsFile:e.target.files[0]
        })
        
       }
    const Edit=()=>{
     
        const formDataToSend = new FormData();
        formDataToSend.append('title', title);
        formDataToSend.append('author', author);
        formDataToSend.append('description', description);
        formDataToSend.append('published_date', date);
        formDataToSend.append('price', price);
        formDataToSend.append('image', image.pictureAsFile);
        axios.post("https://edu-hasnaoui.com/api/"+type_product+"/"+id_product,formDataToSend,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res=>{
            toast.success(''+res.data.update, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        })
        .catch(err=>{
           
            toast.error(''+err.response.data.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
        })
  
    }
   useEffect(()=>{
    setTitle(product.title);
    setAoutor(product.author);
    setDescription(product.description)
    setDate(product.published_date)
    setPrice(product.price)
    
   },[product])
 

 
   
 
  return (
    <div className='detailsProducts'>
        <ToastContainer
            position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
        <ToastContainer />
        <div class="editProduct"  >
            <h1 style={{ textAlign:'center',fontSize:"15px",fontWeight:"bold" }}>Modifie {type_product}</h1>
           <div className='editpgewithImage'>
                 
           
                   
                         <table>
                        <tr>
                            <td>Title :</td>
                            <td><input  value={title} onChange={(e)=>setTitle(e.target.value)}/></td>
                        </tr>
    
                        <tr>
                            <td>Author :</td>
                            <td><input  value={author} onChange={(e)=>setAoutor(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <td>Price :</td>
                            <td><input  value={price} onChange={(e)=>setPrice(e.target.value)}/></td>
                        </tr>
                        <tr>
                            <td>Description :</td>
                            <td>
                                <textarea value={description} onChange={(e)=>setDescription(e.target.value)}>
                                
                                </textarea>
                            </td>
                        </tr>
                        <tr>
                            <td>Image :</td>
                            <td><input type='file' style={{ border:"none" }} onChange={uploadPicture}/></td>
                        </tr>
                        <tr>
                            <td>Date Publication:</td>
                            <td><input type='date' value={date} style={{ border:"none" }}  onChange={(e)=>setDate(e.target.value)} /></td>
                        </tr>
                    </table>
               
        
             
                    
                       
                   <img height={300}  width={200}  src={(image.prictureView===null?"https://edu-hasnaoui.com/image/"+product.image:image.prictureView)} />
                        
                  
        
                 <button className='btn '  onClick={Edit}>Modifie</button>
            
           </div>
            
        
        
                
        </div>
    </div>
  )
}
