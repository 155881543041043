import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import NavbarShop from './NavbarShop';
import InfoClient from './InfoClient';
import { Navigate } from 'react-router-dom';
import Footer from '../Footer';

export default function Checkout() {
  const user = useSelector(state => state.UserReducer);
  const alertShown = useRef(false);

  useEffect(() => {
    if (!user.user.connected && !alertShown.current) {
      alert("You must be logged in to access this page. Please log in to continue.");
      alertShown.current = true; // Set the ref to true after showing the alert
    }
  }, [user.user.connected]);

  if (!user.user.connected) {
    return <Navigate to="/" />;
  }
  return (
 <>
     <div>
      <NavbarShop/>
      <InfoClient/>
    </div>
    <Footer/>
 </>
  )
}
