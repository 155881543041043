import React, {useState } from 'react'
 
 
export default function MainNavbar() {
  const [isactive,setIsActive]=useState(true);
  
 
 

  const changeIcone=()=>{
    const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
    const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
    hamburger.classList.toggle('active');
    mobile_menu.classList.toggle('active')
    setIsActive(!isactive)
  } 
  const moveToElement=()=>{
    if (window.innerWidth<='1196'){
      const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
      const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
      hamburger.classList.toggle('active');
      mobile_menu.classList.toggle('active');
      setIsActive(!isactive)
    } 
  }
 
 
 

  return (
 <>
   
     <section id="header">
      <div className="header">
        <div className="nav-bar" style={{  height:"70px"}}>
          <div className="nav-list" style={{display:"flex" ,width:"100%" }}>
            <div id='hamburger' className="hamburger" style={{ position:"absolute",left:"10px",top:"5.5px" }} onClick={()=>changeIcone()} >
              <div class="bar"></div>
            </div>
            <ul>
              <li><a onClick={()=>moveToElement()} href="/" data-after="Accueil" >Accueil</a></li>
            </ul>
            <div  className={isactive?"sec-center":"noactive"}  style={{ position:"absolute",right:"10px",top:"-8px" }}  > 	
            <div style={{ display:"flex" }}>
    
            </div>
    
           
            
  	     </div>
        
            </div>
          <div/>
        </div>
      </div>
    </section>
 </>
  )
}
