import React from 'react'

export default function Activites() {
  return (
    <section id="activites" style={{ marginTop:"-100px" }}>
    <div className="projects container">
      <div className="projects-header" style={{ marginBottom:"50px" }}>
        <h1 className="section-title"><span>A</span>ctivités <span>S</span>cientifiques</h1>
      </div>
      <div className="all-projects">
      <div class="project-item">
          <div class="project-info">
            
            <h2>International Conference on Pollution Control & Sustainable Environment- Hilton Québec</h2>
            <p style={{ fontSize:"15px" }}>Du 25 au 26 décembre 2023, le Centre des congrès de Hilton- Québec a été l’hôte de la conférence internationale de science society réunissant des chercheurs, professeurs, doctorants et professionnels du monde.  La Conférence est un événement d'envergure dédié à l'exploration des nouvelles technologies et stratégies pour lutter contre la pollution et promouvoir la durabilité environnementale. Elle offrait un espace unique pour partager des connaissances, des expériences et des innovations dans le domaine et découvrir les dernières avancées en matière de technologies de contrôle de la pollution, de gestion des ressources naturelles, et de politiques environnementales efficaces.</p>
          </div>
          <div class="project-img">
          <img src={`/img/ac4.jpg`} alt="img" />
            
          </div>
        </div>
        <div class="project-item">
          <div class="project-info">
            
            <h2>Colloque International sur la Recherche Appliquée en EMFO</h2>
            <p style={{ fontSize:"15px" }}>Ma participation au Colloque International sur la Recherche Appliquée en Economie Management et Finance des Organisations sur le thème : la finance et le développememt économique durable: une alliance prometteuse pour un avenir prospère
              les 26 et 27 avril 2024 
              A ENCG- Béni Mellal
              Ma présence à cette manifestation était l’occasion de partager ma recherche récente sur «les facteurs déterminants de la performance de l’industrie automobile marocaine : une analyse qualitative», et d’échanger avec des chercheurs confirmés et professionnels de renom. Je suis impatient de saisir cette opportunité pour remercier mes collègues du comité d’organisation Pr A., Boudhar  et Pr M., Oudgou.</p>
          </div>
          <div class="project-img">
          <img src={`/img/ac1.jpg`} alt="img" />
          </div>
        </div>
        <div class="project-item">
          <div class="project-info">
    
            <h2>Caravane d’échange sur la loi de finances</h2>
            <p style={{ fontSize:"15px" }}>Un plaisir d’annoncer ma participation à la Caravane annuelle d’échange sur la loi de finances organisée conjointement par le Forum des Chercheurs du ministère de l’Économie et des Finances, et la FSJP Kénitra le 10 /03/2023. Le slogan de cette année « Lois de finances : vers la consécration des valeurs de la citoyenneté financière" met en lumière l'importance cruciale des lois de finances dans la consolidation des principes de transparence financière au sein de la société.
              Je suis heureux de partager que j'ai présenté une conférence à cette occasion, intitulée "évaluation de l'impact des politiques fiscales sur l'économie marocaine". Cette expérience enrichissante et instructive offre un moment propice à l'échange d'idées et à la recherche dans ce domaine vital. J'adresse donc mes plus sincères remerciements aux membres du comité d’organisation pour leur dévouement et contribution significative à la réussite de cet événement académique distingué.</p>
          </div>
          <div class="project-img">
          <img src={`/img/ac2.jpg`} alt="img" />
          </div>
        </div>
        <div class="project-item">
          <div class="project-info">
            <h1></h1>
            <h2>5ème Colloque interdisciplinaire sur la défaillance d’entreprise - Université de Sherbrooke Québec, Canada</h2>
            <p style={{ fontSize:"15px" }}>Ce Colloque interdisciplinaire sur la défaillance d’entreprise, tenu les 28 et 29 avril 2022 à l'Université de Sherbrooke, campus de Longueuil, au Québec, a été un événement crucial pour aborder les multiples facettes de l'échec entrepreneurial. Ainsi, lors de cette manifestation scientifique, nous avions présenté une conférence portant sur les « Difficultés de mise en place des principes coopératifs et possibilités de rebondissement des coopératives de femmes au Maroc ». 
              Placé sous le thème « l’échec entrepreneurial : prévenir, guérir, rebondir », ce colloque a offert un cadre propice pour des échanges approfondis sur les défis contemporains de la défaillance entrepreneuriale. Les chercheurs ont débattu sur la nécessité d'innover et d'adapter des approches éducatives pour mieux répondre aux enjeux de l’échec entrepreneurial.</p>
          </div>
          <div class="project-img">
          <img src={`/img/ac5.jpg`} alt="img" />
          </div>
        </div>
        <div class="project-item">
          <div class="project-info">
            <h1></h1>
            <h2>2ème édition du Colloque International sur l’Evaluation des politiques publiques d’emploi et d’insertion professionnelle des femmes et des jeunes</h2>
            <p style={{ fontSize:"15px" }}>Ce colloque organisé le 20 et 21 mai 2022 à la FEG Kénitra aborde l’évaluation des politiques publiques d’emploi sous le prisme des théories, des techniques, des pratiques ainsi que des enjeux rencontrés par les différentes formes d’organisations (entreprise, administration, etc.). 
              Cette manifestation présente un point de référence des différentes expériences et pratiques en matière d’évaluation des politiques d’emploi au niveau national et international et examine les principaux défis (techniques, systémiques ou culturels) qui doivent être relevés par les chercheurs, les chercheuses et les organes chargés de l’évaluation des politiques publiques.</p>
          </div>
          <div class="project-img">
          <img src={`/img/ac7.jpg`} alt="img" />
          </div>
        </div>
        <div class="project-item">
          <div class="project-info">
            <h1></h1>
            <h2>1ère édition du colloque international « évaluation des politiques publiques (EPP) : enjeux, méthodes et techniques »</h2>
            <p style={{ fontSize:"15px" }}>L’évaluation d’une politique publique consiste à comparer ses objectifs initialement fixés avec les moyens mis en œuvre, ses réalisations en termes de services rendus et avec ses effets sur le territoire concerné par cette politique. Apparue aux Etats-Unis dans les années 1960, l’EPP demeure une activité très récente au Maroc ; ce n’est qu’au début de ce troisième millénaire que cette culture d’évaluation du secteur publica été reconnue notamment avec sa constitutionnalisation (2011) et son institutionnalisation. L’objectif de ce colloque international organisé à la FEG Kénitra le 27 et 28 mai 2021 en partenariat avec l’ambassade britannique à Rabat a été de créer un cadre de réflexion autour de l’EPP. A ce titre, les contributions des participants se sont penchées sur les différents cadres théoriques qui ont abordé la question de l’EPP.</p>
          </div>
          <div class="project-img">
          <img src={`/img/ac6.jpg`} alt="img" />
          </div>
        </div>
        </div>
      </div>
  </section>
  )
}
