import React, { useState } from 'react'
export default function SearchHeader({image,product,text,func,funcValue,recherche}) {
  const [wordSearch,setWordSearch]=useState('')
  const [typeSerch,setTaypeSearch]=useState("liver")
  const headerStyle = {
    backgroundImage:  image,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    color: '#fff',
   
  };
 
  return (
    <div class="seractHeader" style={headerStyle}>
        <div class="overlay"></div>
        <div class="content container">
            <h1>Trouvez  {product} 

            </h1>
            <p>{text}</p>
              <div class="search-bar">
                {recherche&&
                <select style={{background:"none",color:"white",border:"none" }} onChange={(e)=>setTaypeSearch(e.target.value)}>
                  <option value="liver" style={{ color:"black" }}>Version papier</option>
                  <option value="pdf" style={{ color:"black" }}>version numérique</option>
                </select>
                }
                <input type="text" placeholder="Que cherchez-vous...." onKeyDown={(e)=>funcValue(e.target.value)} onChange={(e)=>setWordSearch(e.target.value)} />
                 <button onClick={()=>func(wordSearch,typeSerch)} ><i class="fas fa-search"></i></button>
            </div>
        </div>
    </div>


  )
}


