import React from 'react'
 
export default function E_resouces() {
   const redicert=(route)=>{
        window.location=route;
   }
   
  return (
    <section id="e-resouces">
        <div className="services container">
            <div className="service-top">
                <h1 className="section-title"><span>E</span>-ressources</h1>
                <p>Explorez notre collection variée d'articles, livres et cours gratuits. Trouvez tout ce dont vous avez besoin pour enrichir votre cursus universitaire et professionel.</p>
            </div>
            <div className="service-bottom"  >
                <div className="service-item" id='c1' style={{    cursor: "pointer" }}   onClick={()=>redicert("livers")}>
                    <div className="icon"></div>
                    <h2>Livres</h2>
                    <a className="tex" type='button'>Découvrir les ouvrages aubliés<br></br></a>
                </div>     
                <div className="service-item"  id='c2'  style={{    cursor: "pointer" }} onClick={()=>redicert("articles")} >
                  <div className="icon"></div>
                  <h2>Articles</h2>
                  <a  type='button' className="tex">Consulter les Articles récents<br></br></a>
                </div> 
                <div className="service-item" id="c3"  style={{    cursor: "pointer" }} onClick={()=>redicert("cours")}>
                   <div className="icon" ></div>
                   <h2>Cours</h2>
                   <a type='button' className="tex">Trouver un cours en ligne</a>
                </div>
            </div>
         </div>
    </section>
 
  )
}
