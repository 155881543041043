import React, { useState } from 'react'
import { createContext } from "react";

export const pageDashbord=createContext();

export default function DashbordContext({children}){
    const [products,setProducts]=useState(true);
    const [edit,setEdit]=useState(false);
    const [id_product,setId_Product]=useState(0);
    const [type_product,setType_Product]=useState("");
    const [ajouterProduct,setAjouterProduct]=useState(false);
    const [profile,setProfile]=useState(false);
    const [nameSearch,setNamSearch]=useState("")
  return (
   <pageDashbord.Provider value={{
    products,setProducts,
    edit,setEdit,
    id_product,setId_Product,
    type_product,setType_Product,
    ajouterProduct,setAjouterProduct,
    nameSearch,setNamSearch,
    profile,setProfile

   }}>
      {children}
   </pageDashbord.Provider>
  )
}
