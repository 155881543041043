import axios from 'axios'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Bounce, ToastContainer, toast } from 'react-toastify'

export default function Profile() {
    const[changePass,setChangePass]=useState(false)
    const[changeEmail,setChangeEmail]=useState(false)
    const[changeName,setChangeName]=useState(false)
    const email=useSelector(state=>state.UserReducer).user.email
    const restPassword=()=>{
       setChangePass(true);
       setChangeEmail(false);
       setChangeName(false)
    }
    const changePassword= async()=>{
        await axios.post("https://edu-hasnaoui.com/api/forgetPassword",{email:email})
        .then(res=>
            {
              toast.success(''+res.data.message,{
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
       
             
            })
            .catch(err=> 
            {
                  
              toast.error(''+err.response.data.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
              });
            })
    }
  return (
<div className='detailsProducts'  >
<ToastContainer
            position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
        <ToastContainer />
    <div class="editProduct"  >
        <h1 style={{ textAlign:'center',fontSize:"15px",fontWeight:"bold" }}>Profile</h1>
       <div className='editpgewithImage'>
            <table style={{ height:"150px" }}>
               <tr>
                    <td>Name :</td>       
                    <td style={{textDecoration:"underline" }}>mohamed nouali</td>
                </tr>
                <tr>
                   <td>Email :</td>
                   <td style={{textDecoration:"underline" }}>mohamednouali@gmail.com</td>
               </tr>
                <tr>
                    <td>Password :</td>
                    <td>*****************</td>
                </tr>  
          </table>   
       </div>
       <button onClick={restPassword}>Change Password</button>
       <button style={{ marginLeft:"10px" }}>Change Email</button>
       <button style={{ marginLeft:"10px" }}  >Change Name</button>

       {changePass&&
       <div style={{ width:"400px",height:"200px",display:"flex",flexDirection:"column" ,marginTop:"30px" }}>
         <input className='changeInfo' value={email} disabled />
         <button onClick={changePassword}>change Password</button>
       </div>
       }
       
       {changeEmail&&
       <div style={{ width:"400px",height:"200px",display:"flex",flexDirection:"column" ,marginTop:"30px" }}>
         <input className='changeInfo' value={"mohamednouali@gmail.com"} disabled />
         <button>change Password</button>
       </div>
       }

       
      {changeName&&
        <div style={{ width:"400px",height:"200px",display:"flex",flexDirection:"column" ,marginTop:"30px" }}>
            <input className='changeInfo' value={email} disabled />
            <button onClick={changePassword}>change Password</button>
        </div>
        }
    </div>
 
</div>
  )
}
