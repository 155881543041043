import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import NavbarShop from '../Components/Shop/NavbarShop'
import { Navigate, useParams } from 'react-router-dom'
import Footer from '../Components/Footer'
 
 
export default function Payment() {
  
  const alertShown = useRef(false);
  const user = useSelector(state => state.UserReducer);
  const totalPrice=localStorage.getItem("totalPrice")
  const product=useSelector(state=>state.CartReducer).panier
  const [downlaod,setdownload]=useState(false);
  const {info}=useParams();
  useEffect(() => {
    if (!user.user.connected && !alertShown.current) {
      alert("You must be logged in to access this page. Please log in to continue.");
      alertShown.current = true; // Set the ref to true after showing the alert
    }
  }, [user.user.connected]);

  if (!user.user.connected) {
    return <Navigate to="/" />;
  }
 
  let infoclient;
   try {
    infoclient=JSON.parse(info);
   } catch (e) {
     window.location="/";
   }
 
  let months=["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  const date=new Date();
  infoclient.date=months[date.getMonth()]+" ,"+date.getDate()+", "+(date.getFullYear());
  infoclient.priceTotal=totalPrice;
  const downlaodPdf=async()=>{
      setdownload(true)
      try {
          const response = await axios.get('https://edu-hasnaoui.com/api/download_pdf?data='+JSON.stringify(product)+"&infoclient="+JSON.stringify(infoclient), {
              responseType: 'blob', 
          });
          const blob = new Blob([response.data], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'catalogue.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
      } catch (error) {
          console.error('Error downloading PDF:', error);
      }
      setdownload(false)
  }
  return (
 <>
     <div style={{ backgroundColor:"black" }}>
      <NavbarShop/>
    <header className="headerBank" style={{ marginTop:"70px",color:"white",textAlign:'center' }}>
      <p>En raison de la forte pression, le processus sera accéléré après réception des coordonnées bancaires et l'envoi d'une photo du reçu.</p>
    </header>
    <main className="main-section">
      <h1>Vous pouvez maintenant effectuer le paiement via l'application bancaire ou déposer un montant de {totalPrice} DH à la banque la plus proche de chez vous.</h1>
      <p className='p1'>
      "Veuillez envoyer une photo du reçu et le PDF de vos produits. Cliquez ici pour télécharger le PDF <a type='button' style={{ color:"blue",textDecoration:"underline" }} onClick={downlaodPdf}>{downlaod?<span>Téléchargement...</span>:<span>Télécharger PDF</span>}</a> Vous pouvez l'envoyer via WhatsApp en utilisant le numéro suivant :</p>
      <div className="contact-info">
        <p style={{ fontSize:"15px",fontWeight:"bold" }}>+212 6 45 62 26 74</p>
      </div>
      <div className="bank-info">
        <img src={`/img/CIH.webp`} alt="Cih bank" />
        <div className="bank-details" >
          <p style={{ fontSize:"15px",fontWeight:"bold" }}>Montant: {totalPrice}, 00 Dhs</p>
          <p style={{ fontSize:"15px",fontWeight:"bold" }}>AYMANE DAOUDI</p>
          <p style={{ fontSize:"15px",fontWeight:"bold" }}>RIB: 230 825 4076764211010900 32</p>
          
        </div>
      </div>
    </main>

    <footer className="footerBank">
      <button className="custom-button" onClick={()=>window.location="/checkout"}>
            <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                <path d="M8.293 1.293a1 1 0 011.414 1.414L4.414 8l5.293 5.293a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414l6-6z"/>
            </svg>Retour
        </button>
      
      <div className="whatsapp">
      
        
        <p >Avez-vous une question ?</p>
        <a  a href="https://wa.me/212645622674">

          <img src={`/img/whatsapp.webp`} alt="WhatsApp" />
        </a>
      </div>
    </footer>
    </div>
    <Footer/>
 </>
  )
}
