import React, {useEffect, useState } from 'react'
import '../Cards/Cards.css'
 
import Products from './Products'
export default function CardsProduct() {
  const [element,setElement]=useState("book")
  const [productType,setProductType]=useState("Livers")
  const [ajouterProduct,setAjouterProduct]=useState("Livers")
 
  
 const changeBaground=(it)=>{
    let dives=document.querySelectorAll(".card");
    dives.forEach(item=>item.classList.remove("bagroundChange"));
    it.classList.add("bagroundChange")
    
 }
  const fixedBagroundColor=()=>{
    let dives=document.querySelectorAll(".card");
    dives.forEach(item =>item.addEventListener("click",()=>changeBaground(item)));
     
  }

  const elements=(el,type,ajP)=>{
    setElement(el);
    setProductType(type);
    setAjouterProduct(ajP)
    fixedBagroundColor();
  }
 
  useEffect(fixedBagroundColor,[])
  
  return (
<>
<div class="cardBox">
    <div class="card bagroundChange" onClick={()=>elements("book","Livers","Livers")}>
        <div  onClick={fixedBagroundColor}>
            <div class="numbers"  style={{ textAlign:"center" }}>Livers</div>
                      
        </div>
        <div class="iconBx" style={{ textAlign:"center" }}>
        <ion-icon name="book-outline"></ion-icon>
        </div>
    </div>
    <div class="card"  onClick={()=>elements("article","Articles","Articles")}>
        <div>
            <div class={"numbers"}  style={{ textAlign:"center" }}>Articles</div>
                      
        </div>
        <div class="iconBx" style={{ textAlign:"center" }}>
            <ion-icon name="newspaper-outline"></ion-icon>
        </div>
    </div>
    <div class="card" onClick={()=>elements("cour","Cours","Cours")}  >
        <div>
            <div class="numbers"  style={{ textAlign:"center" }}>Cours</div>
                      
        </div>
        <div class="iconBx" style={{ textAlign:"center" }}>
          <ion-icon name="school-outline"></ion-icon>
        </div>
    </div>

    
    <div class="card"  onClick={()=>elements("pdf","Livers Pdf","Livers Pdf")} >
        <div>
            <div class="numbers"  style={{ textAlign:"center" }}>Pdfs</div>
                      
        </div>
        <div class="iconBx" style={{ textAlign:"center" }}>
           <ion-icon name="document-outline"></ion-icon>
        </div>
    </div>

 
</div>
 <Products element={element} productType={productType} ajouterProduct={ajouterProduct}/> 
</>
  )
}
