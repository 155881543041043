import React from 'react'

export default function Biography() {
  return (
  <section id="biography"style={{ marginTop:"-100px" }} >
     <h1 className="section-title"  style={{ marginTop:"100px" }}  ><span>B</span>iographie </h1>
    <div className="about container">
    
      <div className="col-left"  >
        <div className="about-img">
          <img src="./img/p2.JPG" alt="img"/>
        </div>
        
      </div>
      <div className="col-right" >
         
        <h2> Professeur de l'Enseignement Supérieur </h2>
        <p style={{ fontSize:"15px" }}>
          HASNAOUI Rachid, docteur de l’Université Mohamed V Rabat et diplômé de l’INTEC de paris, Hasnaoui est professeur d’économie et gestion, ancien formateur national du Bureau International de Travail chargé du programme comprendre l’entreprise. Affecté à l’enseignement et la recherche universitaire – auprès de la FEG de Kénitra- depuis janvier 2014, il développe des recherches portant sur les finances et management public ainsi que sur l’évaluation des programmes publics sous la responsabilité du gouvernement marocain. Coordonnateur local (FEGK) du programme DAAM de l’ambassade britannique à Rabat pendant la période : mars 2021- octobre 2022, il a présidé le comité de pilotage des activités de recherche co-organisées avec ladite ambassade. Directeur de l’équipe de recherche finances et fiscalité, il a publié un livre sur les finances publiques et outils de gouvernance financière de l’Etat, en 2019. Ses recherches actuelles portent sur la politique de gestion des eaux ; les énergies renouvelables et les achats publiques durables.! <br/>
        </p>
        <a href="https://orcid.org/0009-0005-8365-3430" className="cta">Plus D'informations</a>
      </div>
    </div>
  </section>
  )
}
