import React, { useContext } from 'react';
import "./Navigation.css";
import { pageDashbord } from '../../Context/DashbordContext';
import axios from 'axios';
import { useDispatch } from 'react-redux';
export default function Navigation() {
    const {setProducts,setEdit,setAjouterProduct,setProfile,}=useContext(pageDashbord)
    const Dispatch=useDispatch()
    let list = document.querySelectorAll(".navigation li");
    function activeLink() {
      list.forEach((item) => {
        item.classList.remove("hovered");
       
      });
      this.classList.add("hovered");
    
    }
   
    list.forEach((item) => item.addEventListener("mouseover", activeLink));
    const Logout=()=>{
        axios.post("https://edu-hasnaoui.com/api/logout")
        .then(res=>res)
        .catch(err=>err)
        Dispatch({type:"logout"});
        window.location="/"
       }
 
  return (
   
        <div class="navigation">
            <ul>
                <li >
                    <a type='button' href='/' >
                        <span class="icon">
                        <ion-icon name="school-outline"></ion-icon>
                        </span>
                        <span class="title">RACHID HASNAOUI</span>
                    </a>
                </li>

                <li onClick={()=>{setProducts(true);setEdit(false);setAjouterProduct(false);setProfile(false)}}>
                    <a href='#' >
                        <span class="icon">
                            <ion-icon name="layers-outline"></ion-icon>
                        </span>
                        <span class="title">Products</span>
                    </a>
                </li> 
                <li onClick={()=>{setProfile(true) ;setProducts(false);setEdit(false);setAjouterProduct(false)}}>
                    <a href="#">
                        <span class="icon">
                          <ion-icon name="person-outline"></ion-icon>
                        </span>
                        <span class="title">Profile</span>
                    </a>
                </li>
                <li>
                    <a href="#" onClick={Logout}>
                        <span class="icon">
                            <ion-icon name="log-out-outline"></ion-icon>
                        </span>
                        <span class="title">Sign Out</span>
                    </a>
                </li>
            </ul>
        </div>
  )
}
