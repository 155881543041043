import React, {useContext, useState } from 'react'
import axios from "axios"
import { LoginContext } from '../Context/LoginContext';
import { toast ,Bounce} from 'react-toastify';
import {useDispatch} from 'react-redux'

export default function Register() {
    const {setLogin,setisRegister}=useContext(LoginContext);
    const [username,setusername]=useState("");
    const [email,setemail]=useState("");
    const [password,setpassword]=useState("");
    const [isemailvalid,setIsemailvalid]=useState(false);
    const vidEmputs=()=>{
      setemail('')
      setusername('')
      setpassword('')
    }
    const Dispatch=useDispatch()
    //check format email is valide
    const checkFormatEmail=()=>{
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)))
        {
          document.getElementById("inputEmail").style.border="2px solid red"
          setIsemailvalid(false);
        }
        else{
          setIsemailvalid(true);
          document.getElementById("inputEmail").style.border="2px solid green"
      
          
        }
    }

   //Register after enter  donnes
    const Register =()=>{
      if (username.length<4) {
        document.getElementById("usernamerror").innerHTML="username must be more than 3  whieths";
        document.getElementById("usernamerror").style.color="red";
        document.getElementById("usernamerror").style.marginTop="-10px";
        document.getElementById("usernamerror").style.marginLeft="15px";
        document.getElementById("usernamerror").style.fontWeight="bold";
        return 0
      }
      document.getElementById("usernamerror").innerHTML="";
      document.getElementById("usernamerror").style.marginTop="0px";
      document.getElementById("usernamerror").style.marginLeft="opx";

      if (!isemailvalid) {
        document.getElementById("emailerror").innerHTML="format email incorect";
        document.getElementById("emailerror").style.color="red";
        document.getElementById("emailerror").style.marginLeft="15px";
        document.getElementById("emailerror").style.fontWeight="bold";
        return 0;
      }
      document.getElementById("emailerror").innerHTML="";
      document.getElementById("emailerror").style.marginTop="0px";
      document.getElementById("emailerror").style.marginLeft="opx";
      if (password.length<8) {
 
        document.getElementById("passworderror").innerHTML="Passord must be more than 8 whegiths";
        document.getElementById("passworderror").style.color="red";
        document.getElementById("passworderror").style.marginTop="-25px";
        document.getElementById("passworderror").style.marginLeft="10px";
        document.getElementById("passworderror").style.fontWeight="bold";

        return 0;
      }
      document.getElementById("passworderror").innerHTML="";
      document.getElementById("passworderror").style.marginTop="0px";
      document.getElementById("passworderror").style.marginLeft="0px";
      axios.post("https://edu-hasnaoui.com/api/register",{name:username,email:email,password:password})
      .then(res=>{
         
          vidEmputs();
          document.getElementById("inputEmail").style.color="rgba(255, 255, 255, .2)";
          Dispatch(
            {
              type:"connected_user",
               payload:{
                id:res.data.user.id,
                name:res.data.user.name,
                email:res.data.user.email,
                role:res.data.user.Role,
              }
            }
          )

       
        window.location="/";
           
      })
      .catch(err=>{
        toast.error(''+err.response.data.message.email, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
      }
   
       )
       setpassword('')
    }
  return (
   <div className="wrapper">
      <h2>S'INSCRIRE</h2>
      <div className="input-box">
        <input type="text" placeholder="Nom d'utilisateur" required value={username} onChange={(e)=>setusername(e.target.value)}/>
        <i className='bx bxs-user mt-2'></i>
        <p id='usernamerror' ></p>
      </div>
      <div className="input-box">
        <input type="eamil" id='inputEmail' onKeyUp={checkFormatEmail}  placeholder="email" value={email} onChange={(e)=>setemail(e.target.value)}   required/>
        <i className='bx bx-envelope'></i>
        <p id='emailerror' > </p>
      </div>
      <div className="input-box">
        <input type="password" placeholder="Mot de passe" required value={password} onChange={(e)=>setpassword(e.target.value)}/>
        <i className='bx bxs-lock-alt' ></i>
      </div>
      <p id='passworderror' ></p>
      <button type="button" onClick={Register} className="btn"  >S'inscrire</button>
      <div className="register-link">
        <p style={{ color:"white" }}>Avez-vous un compte ? <a onClick={()=>{setLogin(true) ;setisRegister(false)}} type='button'>se connecter</a></p>
      </div>
   </div>    
  )
}
