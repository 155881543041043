import React, { useEffect, useState } from 'react'
import NavbarShop from './NavbarShop'
import { useDispatch, useSelector } from 'react-redux';
import axios from "axios"
import { FaRegEye, FaShoppingCart } from 'react-icons/fa';
import {animateScroll as scroll } from 'react-scroll';
import SearchHeader from './SearchHeader';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Footer from '../Footer';
 
export default function Cours() {

  const [cours,seCours]=useState([]);
  const Dispatch=useDispatch();
  
  //add product to cart
  const getAllArticles=()=>{
   axios.get("https://edu-hasnaoui.com/api/cour")
   .then(res=>seCours(res.data.cours))
   .catch(err=>console.log(err));
  }
  useEffect(getAllArticles,[]);
  const addSuccefully=()=>{
    toast.success('adding to cart successfully', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
      return 0
  }
 


  //function for add product to cart
  const addToCart=(item)=>{
    Dispatch({
      type:"addToPanie",
      payload:{
    
        id_product:item.id,
        type_product:"cour",
        price:item.price,
        image:item.image,
        title:item.title,
      }
    })
    Dispatch({
      type:"countProdcuts"
    })
    addSuccefully()
 }

 const funcValue=(value)=>{
  if (value.length<=1) {
    getAllArticles();
  }
}

const Search=async(wordseach)=>{

await axios.get("https://edu-hasnaoui.com/api/cours/search?wordseach="+wordseach)
.then(res=>{
  seCours(res.data.products);     
  scroll.scrollTo(700,{
  duration: 500, // Optional:
  smooth: 'easeInOutQuart', 
  });
})
.catch(err=>alert(err.response.data.message))
}
 
  return (
  <>
    <NavbarShop   />
    <ToastContainer
      position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
    <ToastContainer />
    <SearchHeader image={'url(/img/cours.jpg)'} product={"vos cours & exercices" } text={"Nos vous offrons ici une collection de cours et exercices corrigés dans les domaines essentiels de l'économie et de la gestion, pour niveaux licence et master. Grâce à des modules tels que la fiscalité, les finances publiques, la gestion budgétaire publique, les marchés publics et l'audit et contrôle interne, vous aurez à portée de main tous les outils indispensables pour renforcer vos compétences, exceller et intégrer le monde professionnel."} func={Search} funcValue={funcValue}/>
 
    <section id='product' className="section all-products" style={{ marginTop:"100px" }} >
      <div >
        <h1 style={{ textAlign:"center",marginBottom:"50px",fontSize:"30px", fontFamily:"fatura"}}>Cours & Exercices</h1>
      </div>
      <div className="product-center container">
        {
          cours.map((item,i)=>{
           return <div className="product-item" key={i}>
                      <div className="overlay">
                        <a href="productDetails.html" className="product-thumb">
                          <img src={"https://edu-hasnaoui.com/image/"+item.image} alt="" />
                        </a>
                      </div>
                      <div className="product-info">
                        <span>{item.title}</span>
                        <h4>{item.price}DH</h4>
                      </div>
                      <ul className="icons">
                      <li onClick={()=>window.location="/product_details/"+item.id+"/cour"}><FaRegEye/></li>
                        <li onClick={()=>addToCart(item)} > <FaShoppingCart/></li>
                      </ul>
                   </div>
                
          })
        }
       </div>
    </section>
 <Footer/>
  </>
  )
}
