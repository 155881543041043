import React, { useContext } from 'react'
import Navigation from '../navigation/Navigation'
import MainDashbord from '../MainDashbord/MainDashbord'
import { pageDashbord } from '../../Context/DashbordContext'
import CardsProduct from '../Products/CardsProduct'
import EditProduct from '../Products/EditProduct/EditProduct'
import AjouterProduct from '../Products/AjouterProduct/AjouterProduct'
import Profile from '../Profile/Profile'
import { useSelector } from 'react-redux'
export default function Dashboard() {
 
  const {products,edit,ajouterProduct,profile}=useContext(pageDashbord)
  const user=useSelector(state=>state.UserReducer).user.role;
  
 
 
 
  function activeMenu () {
  let navigation = document.querySelector(".navigation");
  let main = document.querySelector(".main");
  navigation.classList.toggle("active");
  main.classList.toggle("active");

  };
 if (!(user==="Admin")) {
     window.location="/";
     return
 }
 
  return (
    <div className='container dashbord' >
      <Navigation/>
       <div className='main'>
        <MainDashbord func={activeMenu}/>
      {products&&<CardsProduct/>}
         {profile&&<Profile/>}
        {edit&&<EditProduct/>}
        {ajouterProduct&&<AjouterProduct/>}

      </div> 
 
    </div>
  )
}
