import React from 'react'

export default function Footer() {
  return (
    <section id="footer">
        <div className="footer container">
        <div class="brand">
        <h1><span>R</span>achid <span>H</span>asnaoui</h1>
      </div>
      
      <div class="social-icon">
        <div class="social-item">
          <a href="https://www.youtube.com/@RachidHasnaoui/featured" target="_blank"><img src="https://img.icons8.com/bubbles/100/000000/youtube-play.png" /></a>
        </div>
        <div class="social-item">
          <a href="https://www.facebook.com/rachid.hasnaoui.716"><img src="https://img.icons8.com/bubbles/100/000000/facebook-new.png" /></a>
        </div>
        <div class="social-item">
          <a href="https://www.linkedin.com/in/hasnaoui-rachid-6281a639/?midSig=2M6lWefh6OdHg1&eid=28a0ow-lvzan4n4-h0&midToken=AQHV8_GRKOIAXQ&trkEmail=eml-email_welcome_app_download_v1-header-0-profile_glimmer-null-28a0ow%7Elvzan4n4%7Eh0-null-null&trk=eml-email_welcome_app_download_v1-header-0-profile_glimmer&originalSubdomain=ma" target="_blank"><img src="https://img.icons8.com/bubbles/100/000000/linkedin.png" /></a>
        </div>
      </div>
      <p className='footermobile'>Created by   <a style={{ textAlign :"center",color:  'blanchedalmond' ,textDecoration: 'underline', textDecorationColor: 'white'}} href="https://www.linkedin.com/in/aymane-daoudi-3267ba2a6/" target="_blank">AymanDaoudi</a> . All rights reserved Copyright © 2024</p>
    </div>
  </section>
  )
}
