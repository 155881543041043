const INTIINITILAL_STATE={
    cart:0
}
export default function CountProduct(state=INTIINITILAL_STATE,action){
    
    if (action.type==="countProdcuts") 
    {
        return {cart:state.cart+1};
    }
    if (action.type==="incraseByContity") {
           let sub=state.cart-action.payload.numbre
        return {cart:sub};
    }

    if (action.type==="incrase") {
        let sub=state.cart+1
     return {cart:sub};
 }

    if (action.type==="decrase") {
        let sub=state.cart-1
     return {cart:sub};
 }
   
    return state;
}