import React, { useContext, useState } from 'react'
import "./Main.css"
import { pageDashbord } from '../../Context/DashbordContext'
 
export default function MainDashbord({func}) {
    const {products,setNamSearch}=useContext(pageDashbord);
    const [wordSearch,setWordSearch]=useState("");
    const search=()=>{
      setNamSearch(wordSearch);
    }
  return (

 
<div class="topbar">
<div class="toggle" onClick={func}>
        <ion-icon name="menu-outline"></ion-icon>
    </div>

    <div class="search">
             {products&&<label>
            <input type="text" placeholder="Search here" value={wordSearch} onKeyUp={search} onChange={(e)=>setWordSearch(e.target.value)}/>
            <ion-icon name="search-outline"></ion-icon>
        </label>}
    </div>

    <div class="user">
        
    </div>
</div>
 
 
  )
}
