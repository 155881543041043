import React, { useEffect, useState } from 'react'
import NavbarShop from './NavbarShop'
import axios from "axios"
 
import { useDispatch, useSelector } from 'react-redux';
import { FaRegEye, FaShoppingCart } from 'react-icons/fa';
import {animateScroll as scroll } from 'react-scroll';
import SearchHeader from './SearchHeader';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Footer from '../Footer';
export default function Articles() {
   const [articles,setArciles]=useState([]);
   const Dispatch=useDispatch();
  //get all articles from data base by api
   const getAllArticles=()=>{
    axios.get("https://edu-hasnaoui.com/api/article")
    .then(res=>setArciles(res.data.articles))
    .catch(err=>err);
   }
   useEffect(getAllArticles,[]);
   const addSuccefully=()=>{
    toast.success('adding to cart successfully', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
      return 0
  }
 

   // add artcile to panie
   const addToCart=(item)=>{
      Dispatch({
        type:"addToPanie",
        payload:{
    
          id_product:item.id,
          title:item.title,
          type_product:"article",
          price:item.price,
          image:item.image
        }
      })
      Dispatch({
        type:"countProdcuts"
      })
      addSuccefully();
   }

   const funcValue=(value)=>{
      if (value.length<=1) {
        getAllArticles();
      }
   }

   const Search=async(wordseach)=>{
 
    await axios.get("https://edu-hasnaoui.com/api/articles/search?wordseach="+wordseach)
    .then(res=>{
      setArciles(res.data.products);     
      scroll.scrollTo(700,{
      duration: 500, // Optional:
      smooth: 'easeInOutQuart', 
      });
    })
    .catch(err=>alert(err.response.data.message))
  }
 
  return (
  <>
    <NavbarShop/>
    <ToastContainer
      position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
    <ToastContainer />
    <SearchHeader image={'url(/img/Article.jpg)'} product={"vos articles"} text={"Découvrez nos articles scientifiques de pointe en management, économie publique, et évaluation des programmes publics. Ces travaux sont conçus pour les professionnels et les chercheurs. Analyses approfondies, perspectives novatrices et méthodologies rigoureuses, chaque article est un levier pour enrichir votre expertise dans les domaines du management public, de l'économie et de l'évaluation d'impact!"} func={Search} funcValue={funcValue}/>
    <section id='product' className="section all-products" style={{ marginTop:"100px" }} >
      <div >
        <h1 style={{ textAlign:"center",marginBottom:"50px",fontSize:"30px",fontFamily:"fatura" }}>ARTICLES</h1>
      </div>
      <div className="product-center container">
        {
          articles.map((item,i)=>{
            
           return <div className="product-item" key={i}>
                      <div className="overlay">
                        <a href="productDetails.html" className="product-thumb">
                          <img src={"https://edu-hasnaoui.com/image/"+item.image} alt="" />
                        </a>
                      </div>
                      <div className="product-info">
                        <span>{item.title}</span>
                        <h4>{item.price}DH</h4>
                     </div>
                      <ul className="icons">
                      <li onClick={()=>window.location="/product_details/"+item.id+"/article"}><FaRegEye /></li>
                        <li onClick={()=>addToCart(item)} > <FaShoppingCart /></li>
                      </ul>
                   </div>
                 
          })
        }
       </div>
    </section>
 
 <Footer/>
 </>      
  )
}
