import React, { useState } from 'react'
import MainNavbar from './MainNavbar'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast ,Bounce} from 'react-toastify';
import { useDispatch } from 'react-redux'
export default function ResetPassword() {
  const dispatch=useDispatch()
  const {token,email}=useParams()
  const[newPassword,setNewpassword]=useState("");
  const[confirmPassword,setConfirmPassword]=useState("");
  const changePassword=async()=>{
       if (newPassword===confirmPassword) {
         if (newPassword.length>=8) {
            await axios.post("https://edu-hasnaoui.com/api/reset_password",{email:email,password:newPassword,password_confirmation:confirmPassword,token:token})
            .then(res=>
              {          
                dispatch(
                  {
                    type:"connected_user",
                     payload:{
                      id:res.data.user.id,
                      name:res.data.user.name,
                      email:res.data.user.email,
                      role:res.data.user.Role,
                    }
                  }
                )
                window.location="/"
            })
            .catch(err=>
              {
                alert(err.response.data.message);
                  setNewpassword("");
                  setConfirmPassword("")
            })
         }
         else{
          alert("The password field must be at least 8 characters.");
         }
       }
       else{
        alert("The password field confirmation does not match.");
        setConfirmPassword("");
       }
  }
  return (
  <>
    <MainNavbar/>
 
     <div className='biRestPassword'>
        <div className="restPassword">
  
          <h2>Reset Password </h2>
          <div className="input-box">
            <input id='emailLogin'    type="email"  value={email} disabled required/>
            <i className='bx bx-envelope'></i>
            <p id='loginEmailError'></p>
          </div>
            <input id='emailLogin'    type="text"  hidden value={token} disabled required/>
          <div className="input-box">
            <input type="password"   placeholder="New Password" value={newPassword} onChange={(e)=>setNewpassword(e.target.value)} required/>
            <i className='bx bxs-lock-alt' ></i>
            <p  id='loginPasswordError'></p>
          </div>
          <div className="input-box">
            <input type="password"   placeholder="Confirm Password" value={confirmPassword} onChange={(e)=> setConfirmPassword(e.target.value)} required/>
            <i className='bx bxs-lock-alt' ></i>
            <p  id='loginPasswordError'></p>
          </div>
          <button type="button" onClick={changePassword}   className="btn">Reset Password</button>
        </div>
      </div>
 
    
  </>
 
  )
}
