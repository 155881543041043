import React, { useState } from 'react'
export default function InfoClient() {
    const[firstName,setFirstName]=useState("");
    const[LastName,setLastName]=useState("");
    const[email,setemail]=useState("");
    const[phone,setphone]=useState("");
    const[Adress,setAdress]=useState("");
 

  
    const continueCheckout=()=>{
         let pF=document.getElementById("inputFirstname");
         let pL=document.getElementById("inputlastname");
         let pE=document.getElementById("inputemail");
         let pP=document.getElementById("inputPhone");
         let pA=document.getElementById("inputAdress");
         let isAllCorrect=false;
        (/^([a-zA-Z]{4,100})+$/.test(firstName)?(/^([a-zA-Z]{4,100})+$/.test(LastName)?(((/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)))?( (/^(06|07)[1-9]{8}$/.test(phone))?(Adress.length>4?(isAllCorrect=true)&&(pA.innerHTML=""):(pA.innerHTML='L\'adresse est requise')&&(pP.innerHTML="")):(pP.innerHTML='Le numéro de téléphone est invalide')&&(pE.innerHTML="")):(pE.innerHTML= 'L\'adresse e-mail est invalide')&&(pL.innerHTML="")):(pL.innerHTML='Le nom est invalide')&&(pF.innerHTML="")):pF.innerHTML='Le prénom est invalide')
     
        if (isAllCorrect) {
          let info={
            firstname:firstName,
            Lastname:LastName,
            email:email,
            adrees:Adress
           }
           window.location="/checkout/"+JSON.stringify(info);
        }
        return
     }
  return (
    <div style={{display:"flex",justifyContent:"center" }}>
    <div class="bigCheckout" style={{ marginTop:"100px",marginBottom:"100px" }}>
     <div class="checkout">
          <div class="text">
          <h1 >Informations personnelles</h1>
          </div>
          <form >
            <div class="form-row"  >
            <p className='inputVerfication' id='inputFirstname'></p>
                <div class="input-data">
                 
                  <input type="text" value={firstName} required onChange={(e)=>setFirstName(e.target.value)}   />
                  <div class="underline" id='funderline'></div>
                  <label for="">Prènom</label>
                </div>
                <p className='inputVerfication' id='inputlastname'></p>
                <div class="input-data">
                  <input type="text" required value={LastName}  onChange={(e)=>setLastName(e.target.value)} />
                  <div class="underline"  id='lunderline'></div>
                  <label for="">Nom</label>
                </div>
                <p className='inputVerfication' id='inputemail'></p>
                <div class="input-data">
                  <input type="text" required value={email}  onChange={(e)=>setemail(e.target.value)} />
                  <div class="underline"  id='eunderline'></div>
                  <label for="">Email Address</label>
                </div>
                <p className='inputVerfication' id='inputPhone'></p>
                <div class="input-data">
 
                  <input  type="text" required  value={phone} onChange={(e)=>setphone(e.target.value)} />
                  <div class="underline"  id='punderline'></div>
                  <label for="">Tel</label>
                </div>
                <p className='inputVerfication' id='inputAdress'></p>
                <div class="input-data">
                  <input type="text" required value={Adress} onChange={(e)=>setAdress(e.target.value)} />
                  <div class="underline"  id='aunderline'></div>
                  <label for="">Adress</label>
                </div>
 
 
            </div>
    
            <div class="form-row">
            <div class="input-data textarea" >
          
                <div class="form-row submit-btn"  style={{  marginLeft:" -20px",marginTop:"-20px" }}>
                  <div class="input-data">
                      <div class="inner"></div>
                      <input style={{background:"#29323c"}} type="button" onClick={continueCheckout}  value="Continue"/>
                  </div>
                </div>
                </div>
              </div>
          </form>
        </div>
    </div>
</div>
  )
}
