import React, { useEffect, useState } from 'react'
import NavbarShop from './NavbarShop'
import axios from "axios"
import { useDispatch, useSelector } from 'react-redux';
import { FaEye, FaRegEye, FaShoppingCart } from "react-icons/fa";
import SearchHeader from './SearchHeader';
import {animateScroll as scroll } from 'react-scroll';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import Footer from '../Footer';
 
export default function Livers() {
  const [livers,setlivers]=useState([]);
  const Dispatch=useDispatch();
  const [ispdf,setispdf]=useState(false)
  //add product to cart
  const getAllArticles=()=>{
   axios.get("https://edu-hasnaoui.com/api/book")
   .then(res=>setlivers(res.data.books))
   .catch(err=>console.log(err));
   setispdf(false)
  }
  useEffect(getAllArticles,[]);
  const addSuccefully=()=>{
    toast.success('adding to cart successfully', {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      transition: Bounce,
      });
      return 0
  }
 

 

  //function for add product to cart
  const addToCart=(item)=>{
    Dispatch({
      type:"addToPanie",
      payload:{
        id_product:item.id,
        type_product:ispdf?"pdf":"book",
        price:item.price,
        image:item.image,
        title:item.title,
      }
    })
    Dispatch({
      type:"countProdcuts"
    })

    addSuccefully()
    
 }
 const funcValue=(value)=>{
  if (value.length<=1) {
    getAllArticles();
  }
}
const Search=async(wordseach,typeBook)=>{
await axios.get((typeBook=="liver"?"https://edu-hasnaoui.com/api/books/search?wordseach="+wordseach:"https://edu-hasnaoui.com/api/pdf_search/search?wordseach="+wordseach))
.then(res=>{
  setlivers(res.data.products);     
  scroll.scrollTo(700,{
  duration: 500, // Optional:
  smooth: 'easeInOutQuart', 
  });
})
.catch(err=>alert(err.response.data.message))
}

const getBooksPdf=()=>{
  axios.get("https://edu-hasnaoui.com/api/pdf")
   .then(res=>setlivers(res.data.Pfd))
   .catch(err=>console.log(err));
   setispdf(true)
}

 
  return (
    <>
    <NavbarShop   />
    <ToastContainer
      position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
    <ToastContainer />
    <SearchHeader recherche={true} image={'url(/img/livre.jpg)'} func={Search} funcValue={funcValue}  text={"Nos livres couvrent en profondeur les domaines de la gestion et de l'économie publique. La collection de manuels qu'on présente ici porte sur les finances publiques, la technique fiscale, les marchés publics ainsi que l'audit et contrôle interne. Ces livres sont des outils indispensables pour booster vos compétences en Management."} product={"votre livre"}/>
    <section id='product' className="section all-products" style={{ marginTop:"100px" }} >
    <h1 style={{ textAlign:"center",marginBottom:"30px",fontSize:"30px",fontFamily:"fatura", }}>LIVRES</h1>
    <div className='linkLivers' >
      <a onClick={getAllArticles}>Version papier</a>
      <a  onClick={getBooksPdf}>Version numérique</a>
    </div>
      
  
      <div className="product-center container">
        {
          livers.map((item,i)=>{
 
           return <div className="product-item" key={i}>
                      <div className="overlay">
                        <a href="productDetails.html" className="product-thumb">
                          <img src={"https://edu-hasnaoui.com/image/"+item.image} alt="" />
                        </a>
                      </div>
                      <div className="product-info">
                        <span>{item.title}</span>
                        <h4>{item.price}DH</h4>
                      </div>
                      <ul className="icons">
                        <li onClick={()=>{ispdf?window.location="/product_details/"+item.id+"/pdf":window.location="/product_details/"+item.id+"/book"}}><FaRegEye /></li>
                        <li onClick={()=>addToCart(item)} ><FaShoppingCart /></li>
                      </ul>
                   </div>
                
          })
        }
       </div>
    </section>
   <Footer/>
 </>      
  )
}
