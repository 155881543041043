import React from 'react'

export default function Contact() {
  return (
    <section id="contact">
        <div className="contact container">
            <div  >
                <h1 className="section-title"><span>C</span>ontact </h1>
            </div>
            <div className="contact-items">
                <div className="contact-item">
                    <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/phone.png" /></div>
                    <div className="contact-info">
                        <h1>Phone</h1>
                        <h2>+212 661 903 835 </h2>
                        <h2>+212 645 622 674</h2>
                    </div>
                </div>
                <div className="contact-item">
                    <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/new-post.png" /></div>
                    <div className="contact-info">
                        <h1>Email</h1>
                        <h2>rachid.hasnaoui@uit.ac.ma</h2>
                        <h2>rachid3c@yahoo.fr</h2>
                    </div>
                </div>
                <div className="contact-item">
                    <div className="icon"><img src="https://img.icons8.com/bubbles/100/000000/map-marker.png" /></div>
                    <div className="contact-info">
                        <h1>Address</h1>
                        <h2>Univesité Ibn Tofail, Kénitra</h2>
                    </div>
                </div>
            </div>
        </div>
  </section>
  )
}
