 import React, {useEffect, useState } from 'react'
import {useSelector } from 'react-redux'; 
export default function NavbarShop() {
  const [isactive,setIsActive]=useState(true);
 
  const countProducts=useSelector(state=>state.CountProduct.cart);
    const changeIcone=()=>{
      const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
      const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
      hamburger.classList.toggle('active');
      mobile_menu.classList.toggle('active')
      setIsActive(!isactive)
    } 
    const moveToElement=()=>{
      if (window.innerWidth<='1196'){
        const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
        const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
        hamburger.classList.toggle('active');
        mobile_menu.classList.toggle('active');
        setIsActive(!isactive)
      } 
    }

    window.addEventListener("resize",()=>{
      const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
        if (window.innerWidth>"1200") {
           setIsActive(true);
        }
        else{
        if (hamburger.classList[1]) {
           setIsActive(false)
        }
          
        }
   
  
   
     
   })
 
  return (
   <section id="header" style={{ backgroundColor:"#29323c" }}>
 
      <div className="header"  >
        <div className="nav-bar" style={{  height:"70px"}} >
          <div className="nav-list" style={{display:"flex" ,width:"100%"}}>
            <div id='hamburger' className="hamburger" style={{ position:"absolute",left:"10px",top:"5.5px" }} onClick={()=>changeIcone()} >
              <div className="bar"></div>
            </div>
            <ul>
              <li><a onClick={()=>moveToElement()} href="/" data-after="Accueil" >Accueil</a></li> 
              <li><a onClick={()=>moveToElement()} href="/articles" data-after="Articles" >Articles</a></li>
              <li><a onClick={()=>moveToElement()} href="/livers" data-after="Livers" >Livres</a></li>
              <li><a onClick={()=>moveToElement()} href="/cours" data-after="Cours" >Cours</a></li>
            </ul>
            <div  className={isactive?"sec-center":"noactive"}  style={{ position:"absolute",right:"10px",top:"-6px" }}  > 	
              <div>
                <a href='/cart'> 
                  <i  type='button' style={{	position: "relative", right: "10px", top:" 20px",color:"white" ,transform: "translate(-50%)", fontSize: "35px"}} className='bx bx-cart'></i>
                </a>
              </div>
              <div className='cart'>{countProducts}</div>   
            </div>
          </div>
        </div>
      </div>
   </section>
  )
}
