import { combineReducers, createStore } from "redux";
import UserReducer from "./UserReducer";
import CartReducer from "./CartReducer";
import CountProduct from "./CountProduct";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
 

const persistConfig = {
  key: 'root',
   storage,
};
const rootReducers=combineReducers({UserReducer,CartReducer,CountProduct})
const persistedReducer = persistReducer(persistConfig, rootReducers); 
export const Store = createStore(persistedReducer);
   export const persistor = persistStore(Store);