import React, {useContext, useEffect, useState } from 'react'
import Hero from './Accuiel';
import E_resouces from './E_resouces';
import Activites from './Activites';
import Contact from './Contact';
// import Evenements from './Evenements.js';
import {LoginContext} from "../Context/LoginContext"
import Biography from './Biography';
import {animateScroll as scroll } from 'react-scroll';
import { ToastContainer,Bounce, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Footer from './Footer';
 
export default function Header() {
  const [isactive,setIsActive]=useState(true);
  const {isLogin,setLogin,isRegister,setisRegister,setIsReset}=useContext(LoginContext);
  const user=useSelector(state=>state.UserReducer.user);
   
  const Dispatch=useDispatch()
  const changeIcone=()=>{
    const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
    const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
    hamburger.classList.toggle('active');
    mobile_menu.classList.toggle('active')
   
    setIsActive(!isactive)
    
  } 
  const moveToElement=()=>{
    if (window.innerWidth<='1196'){
      const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
      const mobile_menu = document.querySelector('.header .nav-bar .nav-list ul');
      hamburger.classList.toggle('active');
      mobile_menu.classList.toggle('active');
      setIsActive(!isactive)
    } 
  }
 
  const changeLogin=()=>{
    setLogin(!isLogin)
    setisRegister(false)
    setIsReset(false)
     scroll.scrollToTop({
     duration: 500, // Optional:
     smooth: 'easeInOutQuart', 
     });
  }

  const changeRegister=()=>{
    setisRegister(!isRegister)
    setLogin(false);
    setIsReset(false);
    scroll.scrollToTop({
     duration: 500,  
     smooth: 'easeInOutQuart', 
    });
  }
 
   document.addEventListener("scroll",()=>{
    var scroll=window.scrollY;
    let header=document.querySelector(".header")
    if (scroll>250) {
       header.style.backgroundColor="#29323c"
    }
    else{
      header.style.backgroundColor="transparent"
    }
      
      
   })

 
  window.addEventListener("resize",()=>{
    const hamburger = document.querySelector('.header .nav-bar .nav-list .hamburger');
      if (window.innerWidth>"1200") {
         setIsActive(true);
      }
      else{
      if (hamburger.classList[1]) {
         setIsActive(false)
      }
        
      }
 })

 const Logout=()=>{
  axios.post("https://edu-hasnaoui.com/api/logout")
  .then(res=>res)
  .catch(err=>err)
  Dispatch({type:"logout"});
  window.location="/"
 }


 //change color in header


 function changeColorItemsInHeader(){
  let li=document.querySelectorAll(".headerLi");
      
 }

 useEffect(changeColorItemsInHeader,[])
 
 
 
return (
<>
  <section id="header" style={{  height:"70px"}}>
    <ToastContainer
      position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" transition={Bounce}/>
    <ToastContainer />
    <div className="header">
      <div className="nav-bar" style={{  height:"70px"}}>
        <div className="nav-list" style={{display:"flex" ,width:"100%" }}>
          <div id='hamburger' className="hamburger" style={{ position:"absolute",left:"10px",top:"5.5px" }} onClick={()=>changeIcone()} >
            <div class="bar"></div>
          </div>
          <ul>
            <li className='headerLi'><a onClick={()=>moveToElement()}  href="#accuiel" data-after="Accueil" >Accueil</a></li>
            <li className='headerLi'><a onClick={()=>moveToElement()}  href="#e-resouces" data-after="Service">E-ressouces</a></li>
            <li className='headerLi'><a onClick={()=>moveToElement()}  href="#activites" data-after="Projects">Activités</a></li>
            <li className='headerLi'><a onClick={()=>moveToElement()}  href="#biography" data-after="Biography">Biographie</a></li>
            <li className='headerLi'><a onClick={()=>moveToElement()}  href="#contact" data-after="Contact">Contact</a></li>
            {user.role==="Admin"&&<li className='headerLi'><a onClick={()=>moveToElement()}  href="/dashboard" data-after="Dashbord">Dashbord</a></li>}
        
          </ul>
          <div  className={isactive?"sec-center":"noactive"}  style={{ position:"absolute",right:"-25px",top:"-11px" }}  > 	
            <div style={{ display:"flex" }}>
              {
                user.connected ?<li className='logout'><a   style={{ fontSize:"34px",fontWeight:"bold" ,position:"relative",top:"18px",right:"60px"}} type='button' onClick={Logout}  ><i class='bx bx-log-out'></i></a></li>
                :
                <>
                  <input className="dropdown" type="checkbox" id="dropdown" name="dropdown"/>
                  <label type='button' className='loginIcon'   htmlFor="dropdown"><i   className='bx bx-user' style={{position:"absolute", right:"73px",top:"25px",  fontSize: "32px"  }}></i></label>
                  <div className="section-dropdown"> 
                    <div className="section-dropdown-sub"> 
                      
                      <li><a type='button'  onClick={()=>changeLogin() }>Se connecter</a></li>
                      <li> <a   type='button' onClick={()=>changeRegister()}>S'inscrire</a></li>
                    </div>
                 </div>
                </>
              }
            </div>
  	     </div>
        </div>
      </div>
    </div>
  </section>
  <Hero/>
  <E_resouces/>
  <Activites/>
  {/* <Evenements/> */}
  <Biography/>
  <Contact/>
  <Footer/>
</>
  )
}
